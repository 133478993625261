*
{
    margin: 0;
    padding: 0;
}

html,


.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}


.gael{
    position:relative;
    height:10%;
    width:10%;
    left: 18px;
  }
  
  #gael {
    left: 5px;
    top: 10px;
    size: 100em;
    padding: 10px;
    color: rgb(255, 255, 255);
    cursor: pointer;
  }
  #screenshot:hover {
   
    border-right: 4px solid rgb(34, 38, 92);
  }
  #screenshot:focus {
    outline: none;
  }

a {
    position: relative;
    top: -10px;
    left: -38px;
  }

  .text {
    font-family: 'Exo 2', sans-serif;
    color: rgb(255, 255, 255);
    position: relative;
    top: 0px;
    left: 960px;
  }

  h1 {
    font-size: 28px;
  }

  li {
    font-size: 20px;
  }

  p {
    li {
      font-size: 26px;
    }
  }

  .linkedIn_logo { 
  position: absolute;
  top: 514px;
  left: 1397px;
  }

  h2 {
  position: relative;
  font-family: 'Exo 2', sans-serif;
  color: rgb(255, 255, 255);
  font-size: 13px;
  top: 509px;
  left: 1089px;
  }

  .trait {
  position: relative;
  top: 505px;
  left: 899px;
  }



